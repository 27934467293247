import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navbar, Nav, Container, NavDropdown, Badge } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import SearchBox from './SearchBox'
import { logout } from '../actions/userActions'

function Header() {

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const cart = useSelector(state => state.cart)
    const { cartItems } = cart

    const favorite = useSelector(state => state.favorite)
    const { favoriteItems } = favorite

    const dispatch = useDispatch()

    const logoutHandler = () => {
        dispatch(logout())
    }

    return (
        <div>
            <div className='text-white text-center ' 
            style={{background:"#000000"}}>
            
            <p className='lead1'>
                <strong>FREE</strong> shipping on all orders over 50 AWG!
            </p>
            
            </div>
            <br />

            <Navbar className='mt-3' fixed='top' variant="light" expand="md"  collapseOnSelect>
                <Container>
    
                <LinkContainer to='/'>
                <Navbar.Brand><img
                    src="https://i.imgur.com/FQSjmx0.png"
                    width="30"
                    height="30"
                    className="d-inline-block align-top"
                    alt="HealthySkin logo"
                    />HealthySkin
                </Navbar.Brand>
                </LinkContainer>
                
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse className='bg-white' id="responsive-navbar-nav">

                <ul className="navbar-nav mr-auto">
               
                <LinkContainer to='/'>
                    
                    <Nav.Link className="ml-2">
                        Home
                    </Nav.Link>
                    
                </LinkContainer>

                <LinkContainer to='/shop'>
                    
                    <Nav.Link className="ml-2">
                        Shop
                    </Nav.Link>
                    
                </LinkContainer>             
                  
                
                <SearchBox />
                    
                </ul>


                  
                        
                <Nav className="ml-auto">

                {favoriteItems.length === 0 ? (
                    <LinkContainer to='/wishlist'>
                        <Nav.Link className="ml-2"><i className="far fa-heart mr-1" style={{color:'#F08FC0'}}></i>
                        </Nav.Link>
                    </LinkContainer>
                    ) : (
                        <LinkContainer to='/wishlist'>
                        <Nav.Link className="ml-2"><i className="fas fa-solid fa-heart" style={{color:'#F08FC0'}}></i>
                        <Badge>
                        ({favoriteItems.reduce((acc, favoriteItem) => acc + favoriteItem.qty, 0)})
                        </Badge>
                        </Nav.Link>
                    </LinkContainer>
                    )}

                    {cartItems.length === 0 ? (
                    
                    <LinkContainer to='/cart'>
                    <Nav.Link className="ml-2"><i className="fas fa-shopping-cart mr-1"></i>Cart
                    </Nav.Link>
                    </LinkContainer>

                    ) : (
                    <LinkContainer to='/cart'>
                        <Nav.Link className="ml-2"><i className="fas fa-dolly-flatbed"></i>
                        <Badge>
                        ({cartItems.reduce((acc, item) => acc + item.qty, 0)})
                        </Badge>
                        </Nav.Link>
                    </LinkContainer>
                    )}

                    {userInfo ? (
                        <NavDropdown title={`Hello, ${userInfo.name}!`} id='username' className="ml-2">
                            <LinkContainer to='/profile'>
                                <NavDropdown.Item>Profile</NavDropdown.Item>
                            </LinkContainer>

                        <NavDropdown.Item onClick={logoutHandler}>Logout</NavDropdown.Item>

                        </NavDropdown>
                            ) : (
                                    <LinkContainer to='/login'>
                                        <Nav.Link className="ml-2"><i className="fas fa-user-alt"></i> Login </Nav.Link>
                                    </LinkContainer>
                                )}


                            {userInfo && userInfo.isAdmin && (
                                <NavDropdown title='Admin' id='adminmenue' className="ml-2">
                                    <LinkContainer to='/admins/userlist'>
                                        <NavDropdown.Item>Users</NavDropdown.Item>
                                    </LinkContainer>

                                    <LinkContainer to='/admins/productlist'>
                                        <NavDropdown.Item>Products</NavDropdown.Item>
                                    </LinkContainer>

                                    <LinkContainer to='/admins/orderlist'>
                                        <NavDropdown.Item>Orders</NavDropdown.Item>
                                    </LinkContainer>

                                </NavDropdown>
                            )}

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            </div>
    )
}

export default Header
