import React, { useState, useEffect } from 'react'
import { Modal, Row, Col, ListGroup, Image, Card, Container, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { PayPalButton } from 'react-paypal-button-v2'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { createOrder, payOrder } from '../actions/orderActions'
import { ORDER_CREATE_RESET, ORDER_PAY_RESET } from '../constants/orderConstants'




function PlaceOrderScreen({ history, match }) {

    const [showModal, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const orderId = match.params.id

    const orderCreate = useSelector(state => state.orderCreate)
    const { order, error, success } = orderCreate

    const [sdkReady, setSdkReady] = useState(false)

    const orderPay = useSelector(state => state.orderPay)
    const { loading: loadingPay, success: successPay } = orderPay

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const dispatch = useDispatch()

    const cart = useSelector(state => state.cart)

    cart.itemsPrice = cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0).toFixed(2)
    cart.shippingPrice = (cart.itemsPrice > 50 ? 0 : 5.99).toFixed(2)
    cart.taxPrice = Number((0.082) * cart.itemsPrice).toFixed(2)
    cart.giftPrice = (cart.shippingAddress.messageFor !== ""  ? (Number(5).toFixed(2)) : (Number(0).toFixed(2)))
    cart.totalPrice = (Number(cart.itemsPrice) + Number(cart.shippingPrice) + Number(cart.giftPrice)).toFixed(2)

    const addPayPalScript = () => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://www.paypal.com/sdk/js?client-id=AQ7ce-s1Mk09aMpseewDhDYfSoAdiCLjuSzkqXlpotl0x_W70PaieUiZyt7sDynBAHLuAmN-vHMqlgmN'
        script.async = true
        script.onload = () => {
            setSdkReady(true)
        }
        document.body.appendChild(script)
    }

    if (!cart.paymentMethod) {
        history.push('/checkout')
    }

    if (cart.cartItems === 0) {
        history.push('/cart')
    }

    useEffect(() => {

        if (successPay ) {
            
            dispatch({ type: ORDER_CREATE_RESET })
            dispatch({ type: ORDER_PAY_RESET })
            
        } else if (!successPay) {
            if (!window.paypal) {
                addPayPalScript()
            } else {
                setSdkReady(true)
            }
        }
    }, [successPay, history, orderId])

    const placeOrder = () => {
        dispatch(createOrder({
            orderItems: cart.cartItems,
            shippingAddress: cart.shippingAddress,
            paymentMethod: cart.paymentMethod,
            itemsPrice: cart.itemsPrice,
            shippingPrice: cart.shippingPrice,
            giftPrice: cart.giftPrice,
            taxPrice: cart.taxPrice,
            totalPrice: cart.totalPrice,
        }))
        history.push('/profile')
        
    }

    const successPaymentHandler = (paymentResult) => {
        dispatch(payOrder(orderId, paymentResult))
        
    }


    return (
        <div>

            <div className="py-2"
            style={{background:"#4D375D"}}>
            <Container>
                <Row className="row px-4 px-lg-5 py-lg-4 align-items-center">
                   
                    <Col className="col-lg-6">
                        <h1 className="h1 text-uppercase mb-0 text-light">Payment</h1>
                    </Col>

                    <Col className="col-lg-6 text-lg-right">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-lg-end mb-0 px-0"
                            style={{background:"#4D375D"}}>
                                <li className="breadcrumb-item text-light"><div>
                                    <Link to={`/cart`}>    
                                    Cart
                                    </Link>
                                </div></li>
                                <li className="breadcrumb-item text-light">
                                    <Link to={`/checkout`}>    
                                    Checkout
                                    </Link>
                                </li>

                                <li className="breadcrumb-item active" aria-current="page">
                                    Payment
                                </li>
                            </ol>
                        </nav>
                    </Col>

                </Row>
            </Container>
        </div>

            
            <Row>
                <Col md={8}>
                    <ListGroup variant='flush'>
                        <ListGroup.Item>
                            
                            <h2 className="h2 text-uppercase my-3">Shipping</h2>
                            
                            <p>
                                <strong className="text-small text-uppercase">Shipping: </strong>
                                <p className='small '> {cart.shippingAddress.address},  {cart.shippingAddress.city}
                                {'  '}
                                {cart.shippingAddress.postalCode},
                                {'  '}
                                {cart.shippingAddress.country} </p>
                                </p>

                            <p>
                                <strong className="text-small text-uppercase">Phone: </strong>
                                <p className='small  '>
                                    {cart.shippingAddress.phone}
                                    </p>
                            </p>

                             {cart.shippingAddress.messageFor !== "" && (  
                             
                             <Row>
                                    <Col xs={6} lg={2}>
                                        <strong className="text-small text-uppercase">From: </strong>
                                        
                                        <p className='font-italic small'>
                                            {cart.shippingAddress.messageFrom}
                                        </p>
                                    </Col>

                                    <Col xs={6} lg={2}>

                                        <strong className="text-small text-uppercase"> For: </strong>

                                        <p className='font-italic small'>
                                            {cart.shippingAddress.messageFor}
                                        </p>  
                                    </Col>
                                
                                <Col xs={12} lg={6}>
                                <strong className="text-small text-uppercase"> Message: </strong>

                                <p className='font-italic small'>
                                    {cart.shippingAddress.message}
                                </p> 
                                </Col>
                            </Row>

                            )} 
                            
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h2 className="h2 text-uppercase my-3">Payment Method</h2>
                            <p>
                                <strong className="text-small text-uppercase">Method: </strong>
                                <p className='small '> {cart.paymentMethod} </p>
                            </p>
                        </ListGroup.Item>

                        <ListGroup.Item>
                            <h2 className="h2 text-uppercase my-3">Order Items</h2>
                            {cart.cartItems.length === 0 ? <Message variant='info'>
                                Your cart is empty
                            </Message> : (
                                    <ListGroup variant='flush'>
                                        {cart.cartItems.map((item, index) => (
                                            <ListGroup.Item key={index}>
                                                <Row>
                                                    <Col md={1}>
                                                        <Image src={item.image} alt={item.name} fluid rounded />
                                                    </Col>

                                                    <Col className="media-body ml-2">
                                                        <Link to={`/product/${item.product}`}
                                                        className="reset-anchor animsition-link">
                                                            <strong className="h6">{item.name}</strong>
                                                        </Link>
                                                    </Col>

                                                    <Col className="ml-2" md={4}>
                                                    <p className='small text-gray'> 
                                                    {item.qty}un x {item.price} AWG = {(item.qty * item.price).toFixed(2)} AWG
                                                    </p>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                )}
                        </ListGroup.Item>

                    </ListGroup>

                </Col>

                <Col md={4} lg={4}>
                    <Card className="my-3">
                        <ListGroup className="border-0 rounded-0 p-lg-4 justify-content-lg bg-light" variant='flush'>
                            <ListGroup.Item className="bg-light">
                                <h4 className="text-uppercase mb-4">Order Summary</h4>
                            
                                <Row>
                                    <Col><strong className="text-uppercase small font-weight-bold">Items:</strong></Col>
                                    <Col><span className="h6 text-muted">{cart.itemsPrice} AWG</span></Col>
                                </Row>
                            </ListGroup.Item>

                            <ListGroup.Item className="bg-light">
                                <Row>
                                    <Col><strong className="text-uppercase small font-weight-bold">Shipping:</strong></Col>
                                    <Col><span className="h6 text-muted">{cart.shippingPrice} AWG</span></Col>
                                </Row>
                            </ListGroup.Item>

                            {cart.shippingAddress.messageFor !== ""  && (  
                                <ListGroup.Item className="bg-light">
                                <Row>
                                    <Col><strong className="text-uppercase small font-weight-bold">Gift:</strong></Col>
                                    <Col><span className="h6 text-muted">{cart.giftPrice} AWG</span></Col>
                                </Row>
                            </ListGroup.Item>
                                )}

                            <ListGroup.Item className="bg-light">
                                <Row>
                                    <Col><strong className="text-uppercase small font-weight-bold">Total:</strong></Col>
                                    <Col><span className="h6 text-muted">{cart.totalPrice} AWG</span></Col>
                                </Row>
                            </ListGroup.Item>


                            
                                
                                {cart.paymentMethod === "Paypal or Credit Card" ? (
                                <ListGroup.Item className="bg-light">
                                    {error && <Message variant='danger'>{error}</Message>}
                                    {loadingPay && <Loader />}

                                    {!sdkReady ? (
                                        <Loader />
                                    ) : (
                                    <PayPalButton
                                    style= {{
                                        layout:  'vertical',
                                        color:   'blue',
                                        shape:   'rect',
                                        label:   'pay',
                                        
                                        }}
                                        amount={((cart.totalPrice) / Number(1.8)).toFixed(2)}
                                        onSuccess={handleShow}
                                    />
                                    )}
                                </ListGroup.Item>
                            ) : (
                                <ListGroup.Item className='bg-light'>
                                    <Button
                                        type='button'
                                        variant='outline-dark'
                                        className='btn-block'
                                        disabled={cart.cartItems === 0}
                                        onClick={handleShow}
                                    >
                                        Place Order
                                    </Button>
                                </ListGroup.Item>
                            )}
                            
                            
                        </ListGroup>
                    </Card>
                </Col>
            </Row>

            <Modal show={showModal} 
                onHide={handleClose}
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >

            <Modal.Body className='ftco-degree-bg'>      
                        
                <Row >
                    
                
            
                    <Col lg={12}>
                    
                        <button className="close p-4" 
                        type="button" 
                        onClick={placeOrder}>
                            <span aria-hidden="true">
                                ×
                            </span>
                        </button>

                        <div className="p-5 my-md-4">
                        
                       
		     
		        
		      <div className=" text-center">
		      	<h2>Healthy skin here we go!</h2>
		      	<div className="icon d-flex align-items-center justify-content-center">
		      		<img src="https://i.imgur.com/FQSjmx0.png"
                    width="150"
                    height="150" alt="Healthy Skin logo" className="img-fluid" />
		      	</div>
		      	<h6 className="my-3">You can check the order details within your profile. Soon, we will send you an email to:</h6>
		      	<h5>{userInfo.email}</h5>

		      
		  
		</div>
                            
                    
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>    
    
        </div>
    )
}

export default PlaceOrderScreen
