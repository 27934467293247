import React from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'

function AboutScreen() {
    return (
        <div>
            <Card 
            style={{background: "url(https://i.ibb.co/S5ff019/viXOeVl.jpg) center center"}} 
            className=" bg-cover5 text-white mb-4">
                <Container  className=" py-5">
                    <Card.Title className='display-4 font-weight-bold text-center py-3'>
                        About Us
                    </Card.Title>
                </Container>
            </Card>  

            <Container className="mb-5">
            
            <Row>
                <Col lg={4} md={4} sm={6}>
                    <div className="about__item">
                        <h4>Who We Are?</h4>
                        <p className='mb-4'>HealthySkin is a company leaded by skincare professionals
                        and we are committed to offer you the products that truly work on your skin.</p>
                    </div>
                </Col>
                <Col lg={4} md={4} sm={6}>
                    <div className="about__item">
                        <h4>What We Do?</h4>
                        <p className='mb-4'>We take care of giving you the best attention
                         while we bring your favorite products within the reach of a single click.</p>
                    </div>
                    </Col>
                <Col lg={4} md={4} sm={6}>
                    <div className="about__item">
                        <h4>Why Choose Us</h4>
                        <p className='mb-4'> 
                        HealthySkin only works with products that its effects are proven to be excellent and also 
                        cruelty free. </p>
                    </div>
                    </Col>
            </Row>
        </Container>

            <Container>
            <Row className='my-3'>
                <Col lg={8}>
                <div className="card shadow-sm">
                    <div className="instagram__pic">
                        <img className="instagram__pic__item set-bg" 
                        src="https://images.unsplash.com/photo-1624984674735-7ecdbd7de244?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=387&q=80"
                        alt='Woman applying skincare product'></img>
                        
                        <img className="instagram__pic__item set-bg" 
                        src="https://images.unsplash.com/photo-1535031171703-48b1362baa98?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=387&q=80"
                        alt='Woman looking at the mirror with skincare product'></img>
                        
                        <img className="instagram__pic__item set-bg" 
                        src="https://i.imgur.com/fkKUjGX.jpg"
                        alt='Healthy skin serum skincare product'></img>
                        
                        <img className="instagram__pic__item set-bg" 
                        src="https://images.unsplash.com/photo-1526413425697-1d271fdbe7a9?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=464&q=80"
                        alt='Woman back'></img>
                        
                        <img className="instagram__pic__item set-bg" 
                        src="https://images.unsplash.com/photo-1580870069867-74c57ee1bb07?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=435&q=80"
                        alt='Healthy skin The ordinary skincare product'></img>
                        
                        <img className="instagram__pic__item set-bg" 
                        src="https://i.imgur.com/XDE0KD9.jpg"
                        alt='Happy woman with healthy skin'></img>
                    </div>
                </div>
                </Col>
                <Col lg={4} >
                    <div className="instagram__text">
                        <h2>Instagram</h2>
                        <p className='mb-4'>Have you ever wondered<strong> What is vitamin C? How to apply a Serum? Niacinamide? </strong>
                        We know what it's like to live with a lot of products available without knowing how to use them and what they are for.</p>
                        <p className='mb-4'>Follow us on instagram to answers these questions and 
                            also for tips and results of all type of skincare products! Don't forget to share your thoughts and tag us with:</p>
                        <h3>@HealthySkin.au</h3>
                    </div>
                </Col>
            </Row>
        </Container>
        </div>
    )
}

export default AboutScreen
