export const addItemToCart = (cartItems, cartItemToAdd) => {
    //find(condition) finds the first item in the array based on the condition.
    const existingCartItem = cartItems.find(item => item.product === cartItemToAdd.product);
   
    if (existingCartItem) {
      //in order for change detection to trigger we have to rerender
      //otherwise our quantity property will not be updated
      //map will return a new array 
      //we need to return new versions of our state so that our component know to re render
      //here we update the quantity property
      return cartItems.map(item =>
        item.product === cartItemToAdd.product
          ? { ...cartItemToAdd, qty: item.qty + 1 }
          : item
      );
    } 
    //when you first time add a new item, sine exixtingCartItem will be falsy, it will pass the first if block and will come here
    //quantity property gets attached the first time around since this if block wont run when it is a new item.
   //in the beginning cartItems array is empty. every time you add a new item to this array, it will add "quantity:1" to this item object.  
    return [...cartItems, { ...cartItemToAdd, qty: 1 }];
  };