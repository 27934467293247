import React, { useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col, ListGroup, Button, Form } from 'react-bootstrap'
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Rating from '../components/Rating'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { listProductDetails, createProductReview } from '../actions/productActions'
import { PRODUCT_CREATE_REVIEW_RESET } from '../constants/productConstants'

function ProductScreen({ match, history }) {
    const [qty, setQty] = useState(1)
    const [rating, setRating] = useState(0)
    const [comment, setComment] = useState('')

    const dispatch = useDispatch()

    const productDetails = useSelector(state => state.productDetails)
    const { loading, error, product } = productDetails

    const discountPrice = Number(((product.discount / 100) + 1) * product.price).toFixed(2)
    const discountOff = discountPrice === product.price

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const productReviewCreate = useSelector(state => state.productReviewCreate)
    const {
        loading: loadingProductReview,
        error: errorProductReview,
        success: successProductReview,
    } = productReviewCreate

    useEffect(() => {
        if (successProductReview) {
            setRating(0)
            setComment('')
            dispatch({ type: PRODUCT_CREATE_REVIEW_RESET })
        }

        dispatch(listProductDetails(match.params.id))

    }, [dispatch, match, successProductReview])

    const addToCartHandler = () => {
        history.push(`/cart/${match.params.id}?qty=${qty}`)
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(createProductReview(
            match.params.id, {
            rating,
            comment
        }
        ))
    }

    return (
        <div>
            
            {loading ?
                <Loader />
                : error
                    ? <Message variant='danger'>{error}</Message>
                    : (
                <div>
                
                <div className="btn btn-link p-0 text-dark btn-sm">
                    <Link to='/shop' className='text-dark my-2'>
                    <i className="fas fa-long-arrow-alt-left mr-2"> </i>
                    Go back
                    </Link>
                </div>

                <div className="container px-4 px-lg-8">
                    <div className="row gx-4 gx-lg-5 align-items-center">

                    <div className="col-md-5 card-img-top mb-md-0">
                    <img className="card-img-top mb-5 mb-md-0" src={product.image} alt={product.name} />
                    </div>

                    <div className="col-md-6">
                        
                        
                        <Rating value={product.rating} text={` ${product.numReviews} reviews`} color={'#f8e825'} />
                        <h1 className="display-5 fw-bolder">{product.name}</h1>
                        
                        <div className="mb-1">
                        {!discountOff && (
                                <span className="text-decoration-line-through" style={{color:'#FFA0A0'}}>
                                    {discountPrice} AWG
                                </span>
                        )} <span className="h5 text-muted" >
                                <strong> {product.price} AWG</strong>
                            </span>
                        </div>

                        

                        <hr className='style-eight'/>
                        <div className="small mb-1">{product.category}</div>
                        <div className="small mb-1">{product.brand}</div>
                        
                        
                        
                        <p className="lead">{product.description}</p>

                        <Button variant="outline-secondary"  size="sm">{product.size}</Button>
                        <hr className='style-eight'/>
                        
                        <Row className=" align-items-stretch mb-4">

                            <Col className="col-sm-7 pr-sm-0">
                                <div className="border d-flex align-items-center justify-content-between py-1 px-3">
                                    <span className="small text-uppercase text-gray mr-4 no-select">
                                        Quantity
                                    </span>
                                    
                                    <div className="quantity">
                                        {product.countInStock > 0 && (   
                                
                                        <Form.Control 
                                        as="select"
                                        value={qty}
                                        onChange={(e) => setQty(e.target.value)}
                                        >
                                        
                                            {

                                                [...Array(product.countInStock).keys()].map((x) => (
                                                    <option key={x + 1} value={x + 1}>
                                                        {x + 1}
                                                    </option>
                                                ))
                                            }

                                        </Form.Control>
                                        )}

                                    </div>
                                </div>
                            </Col>
                    
                            <Col className="col-sm-5 pl-sm-0">
                                <div 
                                className="btn btn-dark btn-sm btn-block h-100 d-flex align-items-center justify-content-center px-0 text-light" 
                                onClick={addToCartHandler}
                                >
                                    Add to cart
                                </div>
                            </Col>
                    

                        </Row>
                    </div>
                </div>
            </div>


                <div className='py-5' style={{ display: 'block', padding: 30 }}>
                    <Tabs defaultActiveKey="first">

                    <Tab eventKey="first" title="About">
                        <p className="text-muted my-3 text-justify  ">{product.about}</p>        
                    </Tab>

                    <Tab eventKey="second" title="Reviews">
                        <h4>All comments ({product.numReviews})</h4>
                        {product.reviews.length === 0 && <Message variant='dark'>No Reviews</Message>}

                        {product.reviews.map((review) => (
                            
                        <div className="container-m">
                            
                        <div className="row justify-content-center">
                            <div className="col">
                                <div className="card1"> <i className="fa fa-quote-left u-color"></i>
                                    <p>{review.comment}</p>
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="user-about"> 
                                        <span className="font-weight-bold d-block u-color">{review.name}</span> 
                                        <span className="u-color">Customer | {review.createdAt.substring(0, 10)}
                                        </span>

                                        <div className="d-flex flex-row mt-1"> 
                                        <Rating value={review.rating} color='#f8e825' /> 
                                    </div>
                                    </div>
                                        <div className="user-image"> 
                                        <img src="https://i.imgur.com/FQSjmx0.png" 
                                        alt={product.name} 
                                        className="rounded-circle" 
                                        width="70"/> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        ))}

                    </Tab>
                    <Tab eventKey="third" title="Leave your opinion">
                    <ListGroup variant='flush'>
                    <ListGroup.Item>

                        {loadingProductReview && <Loader />}
                        {successProductReview && <Message variant='success'>Review Submitted</Message>}
                        {errorProductReview && <Message variant='danger'>{errorProductReview}</Message>}

                        {userInfo ? (
                            <Form onSubmit={submitHandler}>
                                <Form.Group controlId='rating' >
                                    <Form.Label>Rating</Form.Label>
                                    <Form.Control
                                        as='select'
                                        value={rating}
                                        onChange={(e) => setRating(e.target.value)}
                                    >
                                        <option value=''>Select...</option>
                                        <option value='1'>1 - Poor</option>
                                        <option value='2'>2 - Fair</option>
                                        <option value='3'>3 - Good</option>
                                        <option value='4'>4 - Very Good</option>
                                        <option value='5'>5 - Excellent</option>
                                    </Form.Control>
                                </Form.Group>

                                <Form.Group controlId='comment'>
                                    <Form.Label>Review</Form.Label>
                                    <Form.Control
                                        as='textarea'
                                        rows='5'
                                        value={comment}
                                        onChange={(e) => setComment(e.target.value)}
                                    ></Form.Control>
                                </Form.Group>

                                <Button
                                    disabled={loadingProductReview}
                                    type='submit'
                                    variant='outline-dark'
                                    size='sm'
                                >
                                    Submit
                                </Button>
                            
                            </Form>
                            ) : (
                            <Message variant='info'>Please <Link to='/login'>login</Link> to write a review</Message>
                            )}
                        </ListGroup.Item>
                    </ListGroup>
                    </Tab>
                    </Tabs>           
                    </div>
                </div>
            )
        
        }
    </div>
    )
}

export default ProductScreen
