import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Container, Row, Col, Form } from 'react-bootstrap'
import Toast from 'react-bootstrap/Toast'
import Message from '../components/Message'
import { addToCart, removeFromCart } from '../actions/cartActions'

function CartScreen({ match, location, history }) {
    const productId = match.params.id
    const qty = location.search ? Number(location.search.split('=')[1]) : 1

    const [show, setShow] = useState(false);
    

    const dispatch = useDispatch()

    const cart = useSelector(state => state.cart)
    const { cartItems } = cart

    const existingCartItem = cartItems.find(item => item.countInStock < item.qty);

    useEffect(() => {
        if (productId) {
            dispatch(addToCart(productId, qty))
        }
    }, [dispatch, productId, qty])


    const removeFromCartHandler = (id) => {
        dispatch(removeFromCart(id))
    }

    const checkoutHandler = () => {
        history.push('/login?redirect=checkout')
    }

    return (

    <div>
        <div className="py-2"
        style={{background:"#FFE227"}}>
            <Container>
                <Row className="row px-4 px-lg-5 py-lg-4 align-items-center">
                   
                    <Col className="col-lg-6">
                        <h1 className="h1 text-uppercase mb-0">Cart</h1>
                    </Col>

                    <Col className="col-lg-6 text-lg-right">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-lg-end mb-0 px-0 "
                            style={{background:"#FFE227"}}>
                                <li className="breadcrumb-item"><div>
                                    <Link to={`/`}>    
                                    Home
                                    </Link>
                                </div></li>

                                <li className="breadcrumb-item active" aria-current="page">
                                    Cart
                                </li>
                            </ol>
                        </nav>
                    </Col>

                </Row>
            </Container>
        </div>

        <div className="py-4">
            <Row>
                <div className="col-lg-8 mb-4 mb-lg-0">
                
                    {cartItems.length === 0 ? (
                        <Message variant='info'>
                            Your cart is empty. <Link to='/'>Go Back</Link>
                        </Message>
                    ) : (    
                    <div className="table-responsive mb-4">

                    <table className="table">
                        <thead>
                        <tr>
                            <th className="border-0" scope="col"> 
                            <strong className="text-small text-uppercase">Product</strong>
                            </th>
                            <th className="border-0" scope="col"> 
                            <strong className="text-small text-uppercase">Price</strong>
                            </th>
                            <th className="border-0 " scope="col"> 
                            <strong className="text-small text-uppercase ml-3">Quantity</strong>
                            </th>
                            <th className="border-0" scope="col"> 
                            <strong className="text-small text-uppercase">Total</strong>
                            </th>
                            <th className="border-0" scope="col"> </th>
                        </tr>
                        </thead>

                    {cartItems.map(item => (
                    
                        <tbody>
                        <tr>
                            <th className="pl-0 border-0" scope="row">
                            
                            <div className="media align-items-center">
                                <div className="reset-anchor d-block animsition-link">
                                    <Link to={`/product/${item.product}`}>
                                    <img 
                                    src={item.image} 
                                    alt={item.name} 
                                    width="80"/>
                                    </Link>
                                </div>
                                <div className="media-body ml-2">
                                    <strong className="h6">
                                        <div>
                                        <Link to={`/product/${item.product}`} className="reset-anchor animsition-link">
                                            {item.name}
                                        </Link>
                                        </div>
                                    </strong>
                                </div>
                            </div>

                            </th>

                            <td className="align-middle border-0">
                            <p className="mb-0 small">{item.price} AWG</p>
                            </td>

                            <td className="align-middle border-0">
                                <div 
                                className="border d-flex align-items-center justify-content-between px-3">
                                    <span className="small text-uppercase text-gray headings-font-family ">
                                        Qty
                                    </span>

                                    <div className="quantity">
                                        <Form.Control 
                                        className="form-control form-control-sm border-0 shadow-0 p-0"
                                        as="select"
                                        value={item.qty}
                                        onChange={(e) => dispatch(addToCart(item.product, Number(e.target.value)))}
                                        >
                                        {

                                            [...Array(item.countInStock).keys()].map((x) => (
                                                <option key={x + 1} value={x + 1}>
                                                    {x + 1}
                                                </option>
                                            ))
                                        }

                                        </Form.Control>
                                    </div>
                                </div>
                            </td>

                            <td className="align-middle border-0">
                                <p className="mb-0 ml-4 small">{item.qty}</p>
                            </td>

                            <td className="align-middle border-0">
                                <div className="reset-anchor btn btn-light btn-sm btn-outline-dark" 
                                onClick={() => removeFromCartHandler(item.product)}>
                                    <i className="fas fa-trash-alt small text-muted"></i>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                        ))}
                    </table>
                    </div>

                    )}
                            
                    <div className="px-4 py-3"
                    style={{background:"#F6F6F6"}}>
                        <Row className="row align-items-center text-center">
                            <div className="col-md-6 mb-3 mb-md-0 text-md-left">
                                <div className="btn btn-link p-0 text-dark btn-sm">
                                    <Link to='/shop' className='text-dark'>
                                    <i className="fas fa-long-arrow-alt-left mr-2"> </i>
                                    Continue shopping
                                    </Link>
                                </div>
                            </div>
                        </Row>
                    </div>
                </div>
                
                <div className="col-lg-4">
                    <div className="card border-0 rounded-0 p-lg-4"
                    style={{background:"#F6F6F6"}}>
                    <div className="card-body">
                        <h5 className="text-uppercase mb-4">Cart total</h5>
                        <ul className="list-unstyled mb-0">
                        <li className="d-flex align-items-center justify-content-between">

                            <strong className="text-uppercase small font-weight-bold">
                                Total items
                            </strong>

                            <span className="text-muted">
                            ({cartItems.reduce((acc, item) => acc + item.qty, 0)})
                            </span>
                        </li>
                        <li className="border-bottom my-2"></li>
                        <li className="d-flex align-items-center justify-content-between mb-4">
                            
                            <strong className="text-uppercase small font-weight-bold">
                                Subtotal
                            </strong>
                            
                            <span>
                            {cartItems.reduce((acc, item) => acc + item.qty * item.price, 0).toFixed(2)} AWG
                            </span>
                        </li>
                        
                        <li>      
                                            
                        {existingCartItem ? (    
                            <div>
                            <button 
                            className="btn btn-light btn-outline-dark btn-block " 
                            
                            type="submit"
                            disabled= { cartItems.length === 0 }
                            onClick={() => setShow(true)}
                            > 
                                Procceed to checkout
                                <i className="fas fa-long-arrow-alt-right ml-2"></i>
                            </button> 
                            <hr className='style-eight'/>
                            <p className=" lead my-3 text-center"><span>Guaranteed Safe Checkout</span></p>
                            <img className='mx-auto d-block' src="https://i.imgur.com/bjPAIlY.png"
                             width="200" alt="" />
                            </div>
                        ) : (
                            <div>
                            <button 
                            className="btn btn-light btn-outline-dark btn-block" 
                            type="submit"
                            disabled= {  cartItems.length === 0 }
                            onClick={checkoutHandler}
                            > 
                                Procceed to checkout
                                <i className="fas fa-long-arrow-alt-right ml-2"></i>
                            </button> 
                            <hr className='style-eight mb-4'/>
                            <p className=" lead my-3 text-center"><span>Guaranteed Safe Checkout</span></p>
                            <img className='mx-auto d-block' src="https://i.imgur.com/bjPAIlY.png"
                             width="200" alt="" />
                            </div>
                        )}
                            
                        
                        
                        </li>
                        
                        <Col xs={12}>
                            <Toast className="d-inline-block m-1" 
                            onClose={() => setShow(false)} 
                            show={show} 
                            delay={5000} 
                            autohide
                            className='grey-custom'>
                                <Toast.Header>
                                    <img
                                    src="https://i.imgur.com/FQSjmx0.png"
                                    width="30"
                                    height="30"
                                    className="rounded me-2 "
                                    alt="HealthySkin logo"
                                    />
                                    <strong className="me-auto mr-3">HealthySkin</strong>
                                    <small>1 mins ago</small>
                                </Toast.Header>
                                <Toast.Body> 
                                    As much as we love it, 
                                    keep your quantity product below our total count in stock :)
                                </Toast.Body>
                            </Toast>
                        </Col>
                        
                        </ul> 

                        </div>
                    </div>
                </div>
            </Row>          
        </div>          
    </div>
            
           
    )
}

export default CartScreen