import React, { useState} from 'react'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector,  } from 'react-redux'
import { Modal, Form, Row, Col, Button, Badge  } from 'react-bootstrap'


import Toast from 'react-bootstrap/Toast'
import Rating from './Rating'
import { addToCart, quantityUp } from '../actions/cartActions'
import { addToFavorites, removeFromFavorites } from '../actions/productActions'



function Product({ product }) {
    const [show, setShowA] = useState(false);
    const [showB, setShowB] = useState(false);
    const [showC, setShowC] = useState(false);
    const [showD, setShowD] = useState(false);

    const [showModal, setShow] = useState(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [qty, setQty] = useState(1)
 
    const dispatch = useDispatch()

    const discountPrice = Number(((product.discount / 100) + 1) * product.price).toFixed(2)
    const discountOff = discountPrice === product.price
    const discountBadge = (product.discount / 1).toFixed(0)
    const cart = useSelector(state => state.cart)
    const { cartItems } = cart

    const favorite = useSelector(state => state.favorite)
    const { favoriteItems } = favorite

    const existingFavItem = favoriteItems.find(favoriteItem => favoriteItem.product === product._id)
    
    const addHandler = (e) => {
        e.preventDefault()
        if (cartItems.product === product._id) {
            dispatch(addToCart(product._id, Number(qty)))
        } else {
            dispatch(quantityUp(product._id, Number(qty)))
        }   

    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(addToCart(product._id, Number(qty)))
    }

    const favoriteHandler = (e) => {
        e.preventDefault()
        dispatch(addToFavorites(product._id, Number(qty)))
    }
    
    const removeFromFavHandler = (id) => {
        dispatch(removeFromFavorites(id))
    }

    return (

       
        <div className="product text-center py-3">
            
            <div className="position-relative mb-3 img-wrapper mx-auto d-block">
                
                {product.discount !=="0.00" && product.countInStock !== 0 ? (<Badge pill className='bg-dark text-white'>
                    {discountBadge}%
                </Badge>) : (product.countInStock === 0) ? (<Badge pill className='bg-dark' style={{color:'#FFA0A0'}}>Out of stock</Badge>)
                : (
                    <Badge pill className='bg-white text-white'></Badge>
                )}
                
                <div className="d-block">
                
                    <img className="img-fluid w-100 rounded " src={product.image} alt={product.name}/>
                </div>
                
                <div className="product-overlay">
                    
                <ul className="mb-0 list-inline">

                    <Col xs={16} >    
                        <Toast 
                        onClose={() => setShowA(false)} 
                        show={show} 
                        delay={2000} 
                        autohide
                        className='grey-custom'>
                            <Toast.Body>{product.name} <strong>added to wish list!</strong></Toast.Body>
                        </Toast>
                    </Col>

                    <Col xs={16} >    
                        <Toast 
                        onClose={() => setShowB(false)} 
                        show={showB} 
                        delay={2000} 
                        autohide
                        className='grey-custom'>
                            <Toast.Body>{product.name} <strong>removed from wish list.</strong></Toast.Body>
                        </Toast>
                    </Col>

                    <Col xs={16} >    
                        <Toast 
                        onClose={() => setShowC(false)} 
                        show={showC} 
                        delay={2000} 
                        autohide
                        className='grey-custom'>
                            <Toast.Body>+{qty} <strong>added to cart</strong></Toast.Body>
                        </Toast>
                    </Col>
                    {existingFavItem ? (
                    <li className="list-inline-item mr-2" onClick={() => removeFromFavHandler(product._id)}>
                        <Button className="btn btn-light btn-sm btn-outline-dark" onClick={() => setShowB(true)} >
                            <i className="fas fa-solid fa-heart"></i>
                        </Button>
                    </li>
                    ) : (
                    <li className="list-inline-item mr-2" onClick={favoriteHandler}>
                        <Button className="btn btn-light btn-sm btn-outline-dark" onClick={() => setShowA(true)} >
                            <i className="far fa-heart"></i>
                        </Button>
                    </li>
                    )}
                   
                   {product.countInStock !== 0 ? (<li className="list-inline-item mr-2" onClick={addHandler}
                    >
                    
                        <Button className="btn btn-dark btn-sm btn-outline-light"
                        onClick={() => setShowC(true)}>
                            <i className="fas fa-solid fa-cart-plus"></i>
                        </Button>
                    </li>) : (
                        <li className="list-inline-item mr-2" onClick={addHandler}
                    >
                    
                        <Button variant='dark' size='sm'
                        disabled>
                            <i className="fas fa-solid fa-cart-plus"></i>
                        </Button>
                    </li>
                    )}
                    
                    <li className="list-inline-item mr-0" onClick={handleShow}>
                        <Button className="btn btn-light btn-sm btn-outline-dark">
                            <i className="fas fa-expand" ></i>
                        </Button>
                    </li>

                </ul>
                
            </div>
        </div>

        <h6> 
            <Link className="reset-anchor"
            to={`/product/${product._id}`}>
            {product.name}
            </Link>
        </h6>

        {product.discount !=="0.00" && (
        <div className="text-decoration-line-through small" style={{color:'#FFA0A0'}}>
            {discountPrice} AWG
            </div>
        )}

        <p className="small text-muted">{product.price} AWG</p>
      
        <Modal show={showModal} 
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        >

            <Modal.Body className='bg grey-custom'>      
                        
                <Row className="row align-items-stretch">
                    
                    <div className="col-lg-6 p-lg-0">
                        <div className="product-view d-block h-100 bg-cover bg-center">
                            <img className="img-responsive mx-auto d-block" 
                            src={product.image} 
                            alt={product.brand} 
                            />
                        </div>
                    </div>
            
                    <Col className="col-lg-6">
                    
                        <button className="close p-4" 
                        type="button" 
                        onClick={handleClose}>
                            <span aria-hidden="true">
                                ×
                            </span>
                        </button>

                        <div className="p-5 my-md-4">
                            
                            <ul className="list-inline mb-2">
                                <Rating value={product.rating} 
                                text={`${product.numReviews} reviews`} 
                                color={'#f8e825'} />
                            </ul>

                            <h2 className="h4">{product.name}</h2>


                            <div className="mb-1">
                            {!discountOff && (
                                <span className="text-decoration-line-through" style={{color:'#FFA0A0'}}>
                                    {discountPrice} AWG
                                </span>
                            )} <span className="text-muted">
                                      <strong>{product.price} AWG</strong>
                                </span>
                            </div>
                            
                            <p className="text-small mb-4">{product.description}</p>
                        
                        

                        <Row className=" align-items-stretch mb-4">

                            <Col className="col-sm-7 pr-sm-0">
                                <div className="border d-flex align-items-center justify-content-between py-1 px-3">
                                    <span className="small text-uppercase text-gray mr-4 no-select">
                                        Quantity
                                    </span>
                                    
                                    <div className="quantity">
                                        {product.countInStock > 0 && (   
                                
                                        <Form.Control 
                                        as="select"
                                        value={qty}
                                        onChange={(e) => setQty(e.target.value)}
                                        >
                                        
                                            {

                                                [...Array(product.countInStock).keys()].map((x) => (
                                                    <option key={x + 1} value={x + 1}>
                                                        {x + 1}
                                                    </option>
                                                ))
                                            }

                                        </Form.Control>
                                        )}

                                    </div>
                                </div>
                            </Col>
                    
                            <div className="col-sm-5 pl-sm-0" onClick={submitHandler}>
                                <div 
                                className="btn btn-dark btn-sm btn-block h-100 d-flex align-items-center justify-content-center px-0 text-light" 
                                onClick={() => setShowD(true)}
                                >
                                    Add to cart
                                </div>
                                
                            </div>
                            <Col xs={12} className='ml-auto' >    
                                <Toast 
                                onClose={() => setShowD(false)} 
                                show={showD} 
                                delay={5000} 
                                autohide
                                className='grey-custom'>
                                    <Toast.Body> Updated quantity in your cart: <strong>{qty} un</strong> of <strong>{product.name}.</strong> </Toast.Body>
                                </Toast>
                            </Col>

                        </Row>
                    

                        {existingFavItem ? (
                    
                            <div className="btn btn-link text-dark p-0" onClick={() => removeFromFavHandler(product._id)} >
                                <i className="fas fa-solid fa-heart mr-2"></i> Remove from wish list
                            </div>
                        
                        ) : (
                        
                            <div className="btn btn-link text-dark p-0" onClick={favoriteHandler}>
                                <i className="far fa-heart mr-2"></i> Add to wish list
                            </div>
                        
                        )}

                            <div className="py-2">
                                <Link className="btn btn-link text-dark p-0" to={`/product/${product._id}`}>
                                    <i className="fas fa-arrow-right mr-1"></i> More information
                                </Link>
                            </div>
                    
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal> 

    </div>
    )
}


export default (Product)

