import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Form, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { login } from '../actions/userActions'

function LoginScreen({ location, history }) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')

    const dispatch = useDispatch()

    const redirect = location.search ? location.search.split('=')[1] : '/'

    const userLogin = useSelector(state => state.userLogin)
    const { error, loading, userInfo } = userLogin

    useEffect(() => {
        if (userInfo) {
            history.push(redirect)
        }
    }, [history, userInfo, redirect])

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(login(email, password))
    }

    return (
    <div>
        
        <Container className="container-fluid">
            
            
            <Row className="row no-gutter">

                <div className="col-md-6 d-none d-md-flex bg-image"
                style={{background: "url(https://images.unsplash.com/photo-1608504627482-d32382639b8b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=387&q=80) center center"}}>
                
                </div>


                <div className="col-md-6 bg-light">
                    
                    <div className="login d-flex align-items-center py-5">
                    
                        <Container className="container">
                            <Row className="row">
                                <div className="col-lg-10 col-xl-7 mx-auto">
                        
                                <h3 className="display-4">
                                    Sign Up
                                </h3>
                        
                                <p className="text-muted mb-4">
                                    Where your skin glows, energy flows.
                                </p>

                                <Form onSubmit={submitHandler}>
                                    <div className="form-group mb-3">
                                        <input 
                                        id="inputEmail" 
                                        type="email" 
                                        placeholder="Email address" 
                                        required 
                                        autofocus 
                                        className="form-control rounded-pill border-0 shadow-sm px-4"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}/>
                                    </div>

                                    <div className="form-group mb-3">
                                        <input 
                                        id="inputPassword" 
                                        type="password" 
                                        placeholder="Password" 
                                        required 
                                        className="form-control rounded-pill border-0 shadow-sm px-4 text-primary"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}/>
                                    </div>
                                    
                                    <button 
                                    type="submit" 
                                    className="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm">
                                        Sign in
                                    </button>

                                    {error && <Message variant='danger'>{error}</Message>}
                                    {loading && <Loader />}
                                    
                                </Form>

                                    <div className="text-center w-100 my-3">
                                            <p className="text-muted font-weight-bold">
                                                New Customer?  <span>
                                                <Link
                                                to={redirect ? `/register?redirect=${redirect}` : '/register'}>
                                                    Register
                                                </Link>
                                                </span>
                                            </p>
                                    </div>
                                
                                </div>
                            </Row>
                        </Container>
                    </div>
                </div>
            </Row>
        </Container>
    </div>
    )
}

export default LoginScreen
