import React from 'react'
import { Row, Col, Card, Container } from 'react-bootstrap'

function ContactScreen() {
    return (
        
    <div className="container">
      
      <Card style={{background: "url(https://i.ibb.co/vkVk06M/AOCRMzq.jpg) center center"}}
      className=" bg-cover1 text-white mb-4">
        <Container  className=" py-5">
            <Card.Title className='display-4 font-weight-bold text-center py-5'>
              Contact Us
            </Card.Title>
        </Container>
      </Card>  
      
      <Row className="row justify-content-center">
        <Col md={12}>
          <Row className="row align-items-center">
            <Col lg={8} className="mb-4 mb-lg-0">

              <h2 className="h2 text-uppercase mb-4">Press the button. <br/> It's easy.</h2>

                <Row>
                  <Col md={12} lg={8}>
                  <a href="https://wa.me/2977429297" role="button" className="btn btn-dark btn-block px-5">Send message</a>
                  </Col>
                </Row>
    
            </Col>

              <Col lg={4} className="ml-auto">
              <h3 className="h3 text-uppercase py-3 mb-4">Let's talk about everything.</h3>
                <p className="lead">We're here to help with your questions, 
                  doubts or even give you a tip about your Skincare routines. Don't be ashamed, 
                  we all have been there! :) </p>  
              </Col>
            </Row>
          </Col>  
        </Row>
      </div>
  
        
      
    
    )
}

export default ContactScreen
