import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'

function Footer() {
    return (
        <footer className="gradient-custom">
          <Container className="container py-4">
            <Row className="text-center mb-4">
              <Col md={4} className="mb-3 mb-md-0">
                <h6 className="text-uppercase mb-3">Customer services</h6>
                <ul className="list-unstyled mb-0">
                  <li><Link to='/contact' className="footer-link" >Help &amp; Contact Us</Link></li>
                  <li><Link to='/billing' className="footer-link" >Billing</Link></li>
                  <li><Link to='/profile' className="footer-link" >Profile</Link></li>         
                </ul>
              </Col>
              <Col md={4} className="mb-3 mb-md-0">
                <h6 className="text-uppercase mb-3">Company</h6>
                <ul className="list-unstyled mb-0">
                  <li><Link to='/about' className="footer-link" >About us</Link></li>
                  <li><Link to='/faq' className="footer-link" >FAQs</Link></li>
                  <li><Link to='/register' className="footer-link" >Register</Link></li>
                  
                </ul>
              </Col>
              <Col md={4}>
                <h6 className="text-uppercase mb-3">Social media</h6>
                <ul className="list-unstyled mb-0">
                  <li><a className="footer-link" href="https://www.instagram.com/healthyskin.au/">Instagram</a></li>
                  <li><a className="footer-link" href="https://www.facebook.com/healthyskin.au/">Facebook</a></li> 
                </ul>
              </Col>
            </Row>

            <div className="border-top pt-4 border-color: #1d1d1d">
              <Row>
                <Col lg={12}>
                  <p className="text-center small text-muted mb-0">RR Healthy Skin Products &copy; 2021 All rights reserved.</p>
                </Col>
              </Row>
            </div>
          </Container>    
        </footer>
    )
}

export default Footer
