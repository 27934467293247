import React from 'react'
import { Card, Container, Row, Col, Nav } from 'react-bootstrap'
import Tab from 'react-bootstrap/Tab'


function FAQsScreen() {
    return (
        <Container>
            <Card 
            style={{background: "url(https://i.ibb.co/7XN4c1N/Lt7r7Y3.jpg) center center"}} 
            className=" bg-cover4 text-white mb-4">
                <Container  className=" py-5">
                    <Card.Title className='display-4 font-weight-bold text-center py-3'>
                        FAQ
                    </Card.Title>
                </Container>
            </Card>  

            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                    <Col sm={3}>
                    <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                        <Nav.Link eventKey="first" className="lead"> Why Paypal is the only enable payment method?</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="second" className="lead">How can I track my orders?</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="third" className="lead">Order Statuses</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="fourth" className="lead">Account Registration, Sign In and Password</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                        <Nav.Link eventKey="fifth" className="lead">Returns and Exchanges</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    </Col>
                    <Col sm={9}>
                    <Tab.Content>
                        <Tab.Pane eventKey="first" className="lead text-gray">
                        PayPal is an alternate method for purchasing your order on www.healthyskin.com. 
                        It enables any individual with an 
                        email address to securely send payments online. With a PayPal account, 
                        you can choose to pay with your credit card, debit card, bank account, 
                        or PayPal account balance for any purchase you make.
                        </Tab.Pane>
                        <Tab.Pane eventKey="second" className="lead text-gray">
                        You can track the status of your order online at HealthySkin.com. 
                        Click on "Hello! (Your Name)" at the top of the page and then click on Profile to see your Recent Orders. 
                        Check the order number and click on details for the shipment that you would like to track, 
                        and a detailed order information page will be displayed.  
                        Please estimate 1 - 2 business days from the time your order has been created.
                        </Tab.Pane>
                        <Tab.Pane eventKey="third" className="lead text-gray">
                        <strong>Order Submitted - "Placed"</strong>
                        <div className="mb-4">Once you have placed your order on HealthySkin and your payment
                        has been authorized, your order status will appear as "Placed."</div>

                        <strong>Order Processing - "In Progress"</strong>
                        <div className="mb-4">Once you have placed your order, it will be sent to our warehouse to 
                        be processed and packed for shipment. During this time, your order status will appear as 
                        "In Progress." Please allow 1 - 2 business days for the order to be processed and prepared for shipment.</div>

                        <strong>Order Delivery - "Shipped"</strong>
                        <div className="mb-4">Once we have assigned a tracking number to your order, and it has 
                        left our warehouse, the order status will appear as "Shipped."</div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fourth" className="lead text-gray">
                        <div className="mb-4">To register for HealthySkin.com, all you need to 
                        provide is your name, email address and password.</div>

                        <div className="mb-4">To pay the items in your Basket, be sure to sign in.</div>

                        <strong>Changing Your Login Email Address or Password</strong>
                        <div className="mb-4">You can change your account email address and password on 
                        the Profile page. All your orders confirmations will be sent to your new email address.</div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="fifth" className="lead text-gray">
                        HealthySkin only delivers the best products we have in stock and in each shipment we verify that it 
                        is in the best conditions, that's why Purchases made in HealthySkin store are not elegible for returns
                        and exchanges. HealthySkin reserves the right to limit returns or exchanges in all instances.
                        </Tab.Pane>
                    </Tab.Content>
                    </Col>
                </Row>
                </Tab.Container>

        </Container>
    )
}

export default FAQsScreen
