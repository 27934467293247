import React, { useState, useEffect } from 'react'
import { Button, Row, Col, ListGroup, Image, Card, ProgressBar, Container } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getOrderDetails, payOrder, deliverOrder } from '../actions/orderActions'
import { ORDER_PAY_RESET, ORDER_DELIVER_RESET } from '../constants/orderConstants'

function OrderScreen({ match, history }) {
    const orderId = match.params.id
    const dispatch = useDispatch()


    const [sdkReady, setSdkReady] = useState(false)

    const orderDetails = useSelector(state => state.orderDetails)
    const { order, error, loading } = orderDetails

    const orderPay = useSelector(state => state.orderPay)
    const { loading: loadingPay, success: successPay } = orderPay

    const orderDeliver = useSelector(state => state.orderDeliver)
    const { loading: loadingDeliver, success: successDeliver } = orderDeliver

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin


    if (!loading && !error) {
        order.itemsPrice = order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0).toFixed(2)
    }


    const addPayPalScript = () => {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = 'https://www.paypal.com/sdk/js?client-id=AQ7ce-s1Mk09aMpseewDhDYfSoAdiCLjuSzkqXlpotl0x_W70PaieUiZyt7sDynBAHLuAmN-vHMqlgmN'
        script.async = true
        script.onload = () => {
            setSdkReady(true)
        }
        document.body.appendChild(script)
    }

    useEffect(() => {

        if (!userInfo) {
            history.push('/login')
        }

        if (!order || successPay || order._id !== Number(orderId) || successDeliver) {
            dispatch({ type: ORDER_PAY_RESET })
            dispatch({ type: ORDER_DELIVER_RESET })

            dispatch(getOrderDetails(orderId))
        } else if (!order.isPaid) {
            if (!window.paypal) {
                addPayPalScript()
            } else {
                setSdkReady(true)
            }
        }
    }, [dispatch, order, orderId, successPay, successDeliver])


    const successPaymentHandler = () => {
        dispatch(payOrder(orderId))
    }

    const deliverHandler = () => {
        dispatch(deliverOrder(order))
    }

    return loading ? (
        <Loader />
    ) : error ? (
        <Message variant='danger'>{error}</Message>
    ) : (
                <div>

                    <Card 
                    style={{background: "url(https://i.ibb.co/BwHcL3B/Estudio-de-Fotograf-a-Portada-de-Facebook.png) center center"}} 
                    className=" bg-cover2 text-white mb-4">
                        <Container  className=" py-5">
                            <Card.Title className='display-4 font-weight-bold text-center py-3'>
                                Thanks for your purchase!
                            </Card.Title>
                        </Container>
                    </Card>   


                    
               
            

            <Card className="card shadow mb-4">
                <Card.Body>
                    <Row className='align-items-center'>
                        <Col lg={4}>
                        <h1 className="h1 text-uppercase text-center">Order: HSAU{order._id}</h1>
                        <p className="text-muted text-center">{order.createdAt.substring(0, 10)}   |
                        <span>  {order.orderItems.length} item(s) </span>   |
                        {order.totalPrice} AWG</p>
                        </Col>

                        {!order.isPaid ? (
                        <Col lg={8}>
                            
                                <ProgressBar 
                                    now={40} 
                                    variant="warning"
                                    label="Placed" 
                                    className='progress rounded-pill' 
                                />
                            
                        </Col>
                        ) : !order.isDelivered ? (
                        <Col lg={8}>
                            <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip 
                            id="button-tooltip-2">
                                Verified, we are preparing your products for shipment!
                            </Tooltip>}>
                                <ProgressBar 
                                    now={75} 
                                    label="In Progress" 
                                    className='progress rounded-pill' 
                                />
                            </OverlayTrigger>
                        </Col>
                        ) : (
                        <Col lg={8}>
                            <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip 
                            id="button-tooltip-2">
                                Shipped, tell us your experience with our products!
                            </Tooltip>}>
                                <ProgressBar 
                                    variant="success" 
                                    now={100} 
                                    label="Shipped" 
                                    className='progress rounded-pill' 
                                />
                            </OverlayTrigger>
                        </Col>
                        )}
                        

                    </Row>
                </Card.Body>
            </Card>

                    
                    <Row>
                        <Col md={8}>
                            <ListGroup variant='flush'>
                                <ListGroup.Item>
                                    <h2 className="h4 text-uppercase my-3">Shipping</h2>
                                    <p><strong className="text-small text-uppercase">Name: </strong> {order.user.name} {order.user.lastname}</p>
                                    <p><strong className="text-small text-uppercase">Email: </strong><a href={`mailto:${order.user.email}`}>{order.user.email}</a></p>
                                    <p><strong className="text-small text-uppercase">Phone: </strong> {order.shippingAddress.phone} </p>
                                    <p>
                                        <strong className="text-small text-uppercase">Shipping: </strong>
                                        {order.shippingAddress.address},  {order.shippingAddress.city}
                                        {'  '}
                                        {order.shippingAddress.postalCode},
                                {'  '}
                                        {order.shippingAddress.country}
                                    </p>
                                    {order.shippingAddress.messageFor !== "" && (  
                             
                                    <Row>
                                    <Col xs={6} lg={2}>
                                        <strong className="text-small text-uppercase">From: </strong>
                                        
                                        <p className='font-italic small'>
                                            {order.shippingAddress.messageFrom}
                                        </p>
                                    </Col>

                                    <Col xs={6} lg={2}>

                                        <strong className="text-small text-uppercase"> For: </strong>

                                        <p className='font-italic small'>
                                            {order.shippingAddress.messageFor}
                                        </p>  
                                    </Col>
                                
                                <Col xs={12} lg={6}>
                                <strong className="text-small text-uppercase"> Message: </strong>

                                <p className='font-italic small'>
                                    {order.shippingAddress.message}
                                </p> 
                                </Col>
                            </Row>

                            )}
                                    {order.isDelivered ? (
                                        <Message variant='success'>Delivered on {order.deliveredAt.substring(0, 10)}</Message>
                                    ) : (
                                            <Message variant='warning'>Not Delivered</Message>
                                        )}
                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <h2 className="h4 text-uppercase my-3">Payment Method</h2>
                                    <p>
                                        <strong className="text-small text-uppercase">Method: </strong>
                                        {order.paymentMethod}
                                    </p>
                                    {order.isPaid ? (
                                        <Message variant='success'>Paid on {order.paidAt.substring(0, 10)}</Message>
                                    ) : (
                                            <Message variant='warning'>Payment verification</Message>
                                        )}

                                </ListGroup.Item>

                                <ListGroup.Item>
                                    <h2 className="h4 text-uppercase my-3">Order Items</h2>
                                    {order.orderItems.length === 0 ? <Message variant='info'>
                                        Order is empty
                            </Message> : (
                                            <ListGroup variant='flush'>
                                                {order.orderItems.map((item, index) => (
                                                    <ListGroup.Item key={index}>
                                                        <Row>
                                                            <Col md={3}>
                                                                <Image src={item.image} alt={item.name} fluid rounded />
                                                            </Col>

                                                            <Col>
                                                                <Link to={`/product/${item.product}`}
                                                                className="reset-anchor animsition-link font-weight-bold">
                                                                    {item.name}
                                                                </Link>
                                                            </Col>

                                                            <Col md={4}>
                                                                <p className='small text-gray'>
                                                                    {item.qty} X {item.price} AWG = {(item.qty * item.price).toFixed(2)} AWG
                                                                </p>
                                                            </Col>
                                                        </Row>
                                                    </ListGroup.Item>
                                                ))}
                                            </ListGroup>
                                        )}
                                </ListGroup.Item>

                            </ListGroup>

                        </Col>

                        <Col md={4}>
                            
                                <ListGroup variant='flush' className="list-unstyled mb-0 ">
                                    <ListGroup.Item>
                                        <h3 className="text-uppercase mb-4">Order Summary</h3>
                                        
                                        <Row>
                                            <Col>
                                            <strong className="text-uppercase small font-weight-bold">
                                                Items:
                                            </strong>
                                            </Col>

                                            <Col><span className="text-muted">
                                                {order.itemsPrice} AWG
                                                </span>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>

                                    <ListGroup.Item>
                                        <Row>
                                            <Col><strong className="text-uppercase small font-weight-bold">
                                                Shipping:
                                            </strong>
                                            </Col>

                                            <Col><span className="text-muted">
                                                {order.shippingPrice} AWG
                                            </span></Col>
                                        </Row>
                                    </ListGroup.Item>

                                    {order.shippingAddress.messageFor !== "" && ( 
                                    <ListGroup.Item>
                                        <Row>
                                            <Col><strong className="text-uppercase small font-weight-bold">
                                                Gift:
                                            </strong>
                                            </Col>

                                            <Col><span className="text-muted">
                                                {order.giftPrice} AWG
                                            </span></Col>
                                        </Row>
                                    </ListGroup.Item>
                                    )}
                                    
                              
                                    <ListGroup.Item>
                                        <Row>
                                            <Col><strong className="text-uppercase small font-weight-bold">
                                                Total:
                                            </strong>
                                            </Col>

                                            <Col><span className="text-muted"
                                            >{order.totalPrice} AWG
                                            </span></Col>
                                        </Row>
                                    </ListGroup.Item>


                                    {!order.isPaid && (
                                        <ListGroup.Item>
                                            {loadingPay && <Loader />}

                                            {userInfo && userInfo.isAdmin && !order.isPaid && 
                                               (
                                                <Button
                                                type='button'
                                                className='btn btn-block'
                                                onClick={successPaymentHandler}
                                            >
                                            Mark As Paid
                                            </Button>
                                                )}
                                        </ListGroup.Item>
                                    )}
                                </ListGroup>
                                {loadingDeliver && <Loader />}
                                {userInfo && userInfo.isAdmin && order.isPaid && !order.isDelivered && (
                                    <ListGroup.Item>
                                        <Button
                                            type='button'
                                            className='btn btn-block'
                                            onClick={deliverHandler}
                                        >
                                            Mark As Delivered
                                        </Button>
                                    </ListGroup.Item>
                                )}
                            
                        </Col>
                    </Row>
                </div>
            )
}

export default OrderScreen
