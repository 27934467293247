import { Container } from 'react-bootstrap'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import Header from './components/Header'
import Footer from './components/Footer'
import HomeScreen from './screens/HomeScreen'
import ShopScreen from './screens/ShopScreen'
import ProductScreen from './screens/ProductScreen'
import CartScreen from './screens/CartScreen'
import LoginScreen from './screens/LoginScreen'
import RegisterScreen from './screens/RegisterScreen'
import ProfileScreen from './screens/ProfileScreen'
import FavoriteScreen from './screens/FavoriteScreen'
import CheckoutScreen from './screens/CheckoutScreen'
import PlaceOrderScreen from './screens/PlaceOrderScreen'
import OrderScreen from './screens/OrderScreen'
import UserListScreen from './screens/UserListScreen'
import UserEditScreen from './screens/UserEditScreen'
import ProductListScreen from './screens/ProductListScreen'
import ProductEditScreen from './screens/ProductEditScreen'
import OrderListScreen from './screens/OrderListScreen'
import ContactScreen from './screens/ContactScreen'
import FAQsScreen from './screens/FAQsScreen'
import BillingScreen from './screens/BillingScreen'
import AboutScreen from './screens/AboutScreen'




function App() {
  return (
    <Router>
     
      <Header />
      <main className="my-3">
        <Container>
          <Route path='/' component={HomeScreen} exact />
          <Route path='/login' component={LoginScreen} />
          <Route path='/register' component={RegisterScreen} />
          <Route path='/profile' component={ProfileScreen} />
          <Route path='/wishlist' component={FavoriteScreen} />
          <Route path='/checkout' component={CheckoutScreen} />
          <Route path='/placeorder' component={PlaceOrderScreen} />
          <Route path='/order/:id' component={OrderScreen} />
          <Route path='/shop' component={ShopScreen} />
          <Route path='/product/:id' component={ProductScreen} />
          <Route path='/cart/:id?' component={CartScreen} />

          <Route path='/contact' component={ContactScreen} />
          <Route path='/faq' component={FAQsScreen} />
          <Route path='/billing' component={BillingScreen} />
          <Route path='/about' component={AboutScreen} />
          
          <Route path='/admins/userlist' component={UserListScreen} />
          <Route path='/admins/user/:id/edit' component={UserEditScreen} />

          <Route path='/admins/productlist' component={ProductListScreen} />
          <Route path='/admins/product/:id/edit' component={ProductEditScreen} />

          <Route path='/admins/orderlist' component={OrderListScreen} />
        </Container>
      </main>
      <Footer />
    </Router>
  );
}

export default App;