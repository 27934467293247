import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Row, Col, Button, Container, Image } from 'react-bootstrap'
import Product from '../components/Product'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Paginate from '../components/Paginate'
import { listTopProducts } from '../actions/productActions'
import { Link } from 'react-router-dom'


function HomeScreen({ history }) {
    const dispatch = useDispatch()
    const productTopRated = useSelector(state => state.productTopRated)
    const { error, loading, products, page, pages } = productTopRated

    let keyword = history.location.search

    useEffect(() => {
        dispatch(listTopProducts())

    }, [dispatch])

    

    return (
        <div>
            
            <div>
            
            {loading ? <Loader />
                : error ? <Message variant='danger'>{error}</Message>
                    :
                    <div>
                    <div>
                    <div className="video-background-holder">
                        <div className="video-background-overlay"></div>
                        <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
                                <source src="/static/images/skincare-woman.mp4" type="video/mp4" />
                            </video>
                        <div className="video-background-content container h-100">
                            <div className="d-flex h-100 text-center align-items-center">
                            <div className="w-100 text-white">
                                <h1 className="display-4">Bon Bini Aruba!</h1>
                                <p className="lead mb-4">It's time to light up your skin.</p>
                                <LinkContainer to='/shop'>
                                <Button variant='outline-light' size='sm'className="lead">Browse collections</Button>
                                </LinkContainer>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    <section className="pt-3">
   
                        <header className="text-center">
                            <p className="small text-muted small text-uppercase mb-1">Carefully created collections</p>
                            <h2 className="h5 text-uppercase mb-4">Browse our categories</h2>
                        </header>
                    
                        <Row className="row justify-content-center">
                            
                            <div className="col-md-4 mb-4 mb-md-0">
                            <LinkContainer to='/shop?keyword=hidratator&page=1'>
                                <Link className="category-item" >
                                    <Image fluid src="/static/images/homeImg1.jpg" alt="HealthySkin product category 1" />
                                    <strong className="category-item-title">Hidratators</strong>
                                </Link>
                            </LinkContainer>
                            </div>
                            
                            <div  className="col-md-4 mb-4 mb-md-0">
                            <LinkContainer to='/shop'>
                                <Link className="category-item">
                                    <Image fluid src="/static/images/homeImg2.jpg" alt="HealthySkin product category 2" />
                                    <strong className="category-item-title">Cleansers</strong>
                                </Link>
                            </LinkContainer>
                            </div>
                            
                            <div className="col-md-4 mb-4 mb-md-0">
                            <LinkContainer to='/shop?keyword=serum&page=1'>
                                <Link className="category-item">
                                    <Image fluid src="/static/images/homeImg3.jpg" alt="HealthySkin product category 3" />
                                    <strong className="category-item-title">Serums</strong>
                                </Link>
                            </LinkContainer>
                            </div>
                        </Row>
                    </section>
                    
                    <section className="py-5">
                        <header>
                            <p className="small text-muted small text-uppercase mb-1">Made the hard way</p>
                            <h2 className="h5 text-uppercase mb-4">Top trending products</h2>
                        </header>
                        
                        <Row>
                            {products.map(product => (
                                <Col key={product._id} sm={12} md={6} lg={4} xl={3}>
                                    <Product product={product} />
                                </Col>
                            ))}
                        </Row>
                    </section>
                        
                    <Paginate page={page} pages={pages} keyword={keyword} />
                        
                    </div>
            
            }
        </div> 
           
        <div className="py-5 bg-light">
            <Container>
                <Row className="text-center">
                <Col lg={4} className="mb-3 mb-lg-0">
                    <div className="d-inline-block">
                    <div className="media align-items-end">
                        
                        <Image
                        src="/static/images/homeIcon1.png"
                        width="30"
                        height="30"
                        className="svg-icon svg-icon-big svg-icon-light"
                        alt="Free Shipping Healthy Skin"
                        />

                        <div className="media-body text-left ml-3">
                        <h6 className="text-uppercase mb-1">Free shipping</h6>
                        <p className="text-small mb-0 text-muted">on orders over 50 AWG</p>
                        </div>
                    </div>
                    </div>
                </Col>
                <Col lg={4} className="mb-3 mb-lg-0">
                    <div className="d-inline-block">
                    <div className="media align-items-end">
                        
                        <Image
                        src="/static/images/homeIcon2.png"
                        width="30"
                        height="30"
                        className="svg-icon svg-icon-big svg-icon-light"
                        alt="Top products Healthy Skin"
                        />

                        <div className="media-body text-left ml-3">
                        <h6 className="text-uppercase mb-1">Only the best</h6>
                        <p className="text-small mb-0 text-muted">Products for your skin</p>
                        </div>
                    </div>
                    </div>
                </Col>

                <Col lg={4}>
                    <div className="d-inline-block">
                    <div className="media align-items-end">

                        <Image
                        src="/static/images/homeIcon3.png"
                        width="30"
                        height="30"
                        className="svg-icon svg-icon-big svg-icon-light"
                        alt="Good tips Healthy Skin"
                        />

                        <div className="media-body text-left ml-3">
                        <h6 className="text-uppercase mb-1">Follow us</h6>
                        <p className="text-small mb-0 text-muted">for top skincare tips</p>
                        </div>
                    </div>
                    </div>
                </Col>
                </Row>
            </Container>
        </div>
    </div>

            

        
    )
}

export default HomeScreen

