import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Row, Col, Container } from 'react-bootstrap'
import Product from '../components/Product'
import Loader from '../components/Loader'
import Message from '../components/Message'
import Paginate from '../components/Paginate'
import { listProducts } from '../actions/productActions'

function ShopScreen({history}) {
    
    const dispatch = useDispatch()
    const productList = useSelector(state => state.productList)
    const { error, loading, products, page, pages } = productList


    const productDetails = useSelector(state => state.productDetails)
    
    
    const uniqueTags1 = [];

    const diferentCategories = [...new Set(products.map( x => x.category ))]
    const diferentBrands = [...new Set(products.map( x => x.brand ))]


    let keyword = history.location.search

    useEffect(() => {
        dispatch(listProducts(keyword))

    }, [dispatch, keyword])
    
        
    
    return (

    <div>
        <div className="py-2"
        style={{background:"#FEF1E6"}}>
                <Container className="container">
                <Row className="row px-4 px-lg-5 py-lg-4 align-items-center">
                    <Col className="col-lg-6">
                    <h1 className="h1 text-uppercase mb-0">Shop</h1>
                    </Col>

                    <Col className="col-lg-6 text-lg-right">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-lg-end mb-0 px-0"
                            style={{background:"#FEF1E6"}}>
                                <li className="breadcrumb-item"><div>
                                    <Link to={`/`}>  
                                    Home
                                    </Link>
                                </div></li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Shop
                                </li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
            </Container>
        </div>
        
        <div className="py-5 ">
        <Row >
            <Col xs={5} md={3}>
            <h5 className="text-uppercase mb-4">Categories</h5>
                <div className="py-2 px-4 text-white mb-3"
                style={{background:"#F9D5A7"}}><strong className="small text-uppercase font-weight-bold">Brand</strong></div>
                <ul className="list-unstyled small text-muted pl-lg-4 font-weight-normal">
                
                {diferentBrands.map(item => 
                    
                    <li className="mb-2">
                        <Link className="reset-anchor" to={`/shop?keyword=${item}&page=1`}>
                        {item}
                        </Link>
                    </li>
                )}
                </ul>
                <div className="py-2 px-4 mb-3"
                style={{background:"#FEF1E6"}}><strong className="small text-uppercase font-weight-bold">Type</strong></div>
                <ul className="list-unstyled small text-muted pl-lg-4 font-weight-normal">
                
            
                {diferentCategories.map(item => 
                    
                    <li className="mb-2">
                        <Link className="reset-anchor" to={`/shop?keyword=${item}&page=1`}>
                        {item}
                        </Link>
                    </li>
                )}

                </ul>
            
              </Col>
              {loading ? <Loader />
                : error ? <Message variant='danger'>{error}</Message>
                    :
              <Col xs={6} md={8}>
                <Row xs={1} md={3} >
                                {products.map(product => (
                                    
                                        <Product product={product} />
                                    
                                ))}
                      </Row>      
                </Col>
            }

                  
              </Row>
            
              <Paginate page={page} pages={pages} keyword={keyword} />
           
        </div>
      </div>

        
    
       
    )
}

export default ShopScreen
