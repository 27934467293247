import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Form, FormControl, Container, Row, Col  } from 'react-bootstrap'
import InputGroup from 'react-bootstrap/InputGroup'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Collapse from 'react-bootstrap/Collapse'
import { useDispatch, useSelector } from 'react-redux'
import { saveShippingAddress, savePaymentMethod } from '../actions/cartActions'

function CheckoutScreen({ history }) {

    const cart = useSelector(state => state.cart)
    const { shippingAddress } = cart

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const [open, setOpen] = useState(false)

    const dispatch = useDispatch()

    const [paymentMethod, setPaymentMethod] = useState('Paypal or Credit Card')
    const [address, setAddress] = useState(shippingAddress.address)
    const [city, setCity] = useState(shippingAddress.city)
    const [phone, setPhone] = useState(shippingAddress.phone)
    const [postalCode, setPostalCode] = useState(shippingAddress.postalCode)
    const [country, setCountry] = useState(shippingAddress.country)
    const [messageFor, setMessageFor] = useState("")
    const [messageFrom, setMessageFrom] = useState("")
    const [message, setMessage] = useState("")

    if (!userInfo) {
        history.push('/login')
    }

    const submitHandler = (e) => {
        e.preventDefault()
        dispatch(saveShippingAddress({ address, city, postalCode, country, phone, messageFor, messageFrom, message }))
        dispatch(savePaymentMethod(paymentMethod))
        history.push('/placeorder')
    }

    return (
        <div>

        <div className="py-2"
        style={{background:"#FFB4B4"}}>
                <Container className="container">
                <Row className="px-4 px-lg-5 py-lg-4 align-items-center">
                    <Col className="col-lg-6">
                    <h1 className="h1 text-uppercase mb-0 text-light">Checkout</h1>
                    </Col>

                    <Col className="col-lg-6 text-lg-right">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-lg-end mb-0 px-0"
                            style={{background:"#FFB4B4"}}>
                                <li className="breadcrumb-item text-light"><div>
                                    <Link to={`/cart`}>  
                                    Cart
                                    </Link>
                                </div></li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Checkout
                                </li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
            </Container>
        </div>

  


        <h2 className="h5 text-uppercase my-3">Billing details</h2>
          <Row>
            <Col lg={8}>
              <Form onSubmit={submitHandler}>
                <Row>

                  <Col lg={6} className="form-group">
                    <label className="text-small text-uppercase" for="firstName">
                        First name
                    </label>

                    <FormControl
                    size="lg"
                    id="firstName" 
                    type="text" 
                    placeholder="Enter your first name"
                    value={userInfo.name}
                    required
                    />
                    
                  </Col>

                  <Col lg={6} className="form-group">
                    <label className="text-small text-uppercase" for="lastName">
                        Last name
                    </label>

                    <FormControl
                    size="lg" 
                    id="lastName" 
                    type="text" 
                    placeholder="Enter your last name"
                    required
                    value={userInfo.lastname}
                    />
                    
                  </Col>
                  
                  <Col lg={6} className="form-group">
                    <label 
                    className="text-small text-uppercase" 
                    for="phone">
                        Phone number
                    </label>

                    <InputGroup>
                    
                    <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip 
                        id="button-tooltip-2">
                            In case we have to contact you about your order.
                        </Tooltip>}
                    >
                        {({ ref, ...triggerHandler }) => (
                        
                        
                            
                            <InputGroup.Text {...triggerHandler} 
                            className="bg-white rounded-0" id="phone" >
                                <i ref={ref} className="fas fa-info-circle"></i>
                            </InputGroup.Text>
                        
                        )}
                    </OverlayTrigger>
                    

                    <FormControl
                    size="lg"
                    id="phone" 
                    type="text" 
                    placeholder="e.g. +297 245 3547"
                    value={phone ? phone : ''}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                    />
                    </InputGroup>
                    
                  </Col>

                  <Col lg={6}>
                      
                        <label className="text-small text-uppercase" for="payment">Payment method</label>
                        
                    <select className="form-control form-control-lg" id="Paypal or Credit Card" 
                    data-title="Select your payment method"
                    onChange={(e) => setPaymentMethod(e.target.value)}
                    required>
                        <option value=''>Select</option>
                        <option value='Paypal or Credit Card'>Paypal or Credit Card</option>
                        <option value='Bank transfer'>Bank transfer</option>
                        <option value='Cash'>Cash</option>
                                           
                    </select>
                    </Col>


                  <Col lg={12} className="form-group">
                    <label className="text-small text-uppercase" for="address">
                        Address
                    </label>

                    <FormControl
                    size="lg" 
                    id="address" type="text" 
                    placeholder="Enter your address"
                    value={address ? address : ''}
                    onChange={(e) => setAddress(e.target.value)}
                    required/>
                  </Col>

                <Col lg={4} className="form-group">
                    <label 
                    className="text-small text-uppercase" 
                    for="city">
                        City
                    </label>

                    <FormControl
                    size="lg"
                    id="city" 
                    type="text" 
                    placeholder="Enter your city"
                    value={city ? city : ''}
                    onChange={(e) => setCity(e.target.value)}
                    required/>
                </Col>

                  <Col lg={4} className="form-group">
                    <label 
                    className="text-small text-uppercase" 
                    for="country">
                        Country
                    </label>

                    <FormControl
                    size="lg"
                    id="country" 
                    type="text" 
                    placeholder="Enter your country"
                    value={country ? country : ''}
                    onChange={(e) => setCountry(e.target.value)}
                    required/>
                  </Col>

                  <Col lg={4}>
                    <label className="text-small text-uppercase" for="postalcode">
                        Postal Code
                    </label>
                    <FormControl
                    size="lg" 
                    id="postalcode" 
                    type="text"
                    placeholder="Enter your postal code"
                    value={postalCode ? postalCode : ''}
                    onChange={(e) => setPostalCode(e.target.value)}
                    required/>
                  </Col>

                  
                  
                  <Col lg={6}>
                    <div className="custom-control custom-checkbox">
                      <input className="custom-control-input" 
                      id="isAGift" 
                      type="checkbox" 
                      onClick={() => setOpen(!open)}
                      aria-controls="example-collapse-text"
                      aria-expanded={open}/>
                      <label className="custom-control-label text-small font-weight-bold" for="isAGift">Is a gift?</label>
                    </div>
                  </Col>


                  
                  <Collapse in={open}>
                      
                     
                  <Col lg={12}>
                    <Row lg={2}>
                        <Col xs={6} lg={6} className=" form-group">
                            <label 
                            className="text-small text-uppercase" 
                            for="from">
                                From
                            </label>

                            <FormControl
                            size="lg" 
                            id="from" 
                            type="text" 
                            placeholder="You can be creative"
                            value={messageFrom ? messageFrom : ''}
                            onChange={(e) => setMessageFrom(e.target.value)}
                            />
                        </Col>

                        <Col xs={6} lg={6} >
                            <label 
                            className="text-small text-uppercase" 
                            for="to">
                                To
                            </label>

                            <FormControl
                            size="lg"
                            id="to" 
                            type="text" 
                            placeholder="You can be creative here too!"
                            value={messageFor ? messageFor : ''}
                            onChange={(e) => setMessageFor(e.target.value)}
                            />
                        </Col>
                        
                        
                        <Col lg={12} className="col form-group">
                            <Form.Control
                            as="textarea"
                            style={{ height: '100px' }} 
                            placeholder="Write your message"
                            value={message ? message : ''}
                            onChange={(e) => setMessage(e.target.value)}
                            />
                        </Col>
                    </Row>
                  </Col>
                   
                </Collapse>
    
                </Row>
                <button 
                            className="btn btn-light btn-outline-dark btn-block my-2" 
                            type="submit"
                            onSubmit={submitHandler}
                            > 
                                Procceed to Payment
                                <i className="fas fa-long-arrow-alt-right ml-2"></i>
                        </button>
              </Form>
            </Col>
            




            <div className="col-lg-4">
              <div className="card border-0 rounded-0 p-lg-4 bg-light">
                <div className="card-body">
                  <h5 className="text-uppercase mb-4">Your order</h5>
                  {cart.cartItems.map(item => (
                  <ul className="list-unstyled mb-0">
                    
                    <li className="d-flex align-items-center justify-content-between">
                        <strong className="small font-weight-bold">
                            {item.name}
                            </strong>

                            <span className="text-muted small">
                            {item.price} AWG
                            </span>
                    </li>
                    
                    
                    <li className="border-bottom my-2"></li>
                  </ul>
                  ))}
                </div>
              </div>
            </div>
          </Row>
        
      
    </div>
    )
}

export default CheckoutScreen
