import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Container, Form, Button, Row, Col, Table } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { getUserDetails, updateUserProfile } from '../actions/userActions'
import { USER_UPDATE_PROFILE_RESET } from '../constants/userConstants'
import { listMyOrders } from '../actions/orderActions'

function ProfileScreen({ history }) {

    const [name, setName] = useState('')
    const [lastname, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()

    const userDetails = useSelector(state => state.userDetails)
    const { error, loading, user } = userDetails

    const userLogin = useSelector(state => state.userLogin)
    const { userInfo } = userLogin

    const userUpdateProfile = useSelector(state => state.userUpdateProfile)
    const { success } = userUpdateProfile

    const orderListMy = useSelector(state => state.orderListMy)
    const { loading: loadingOrders, error: errorOrders, orders } = orderListMy


    useEffect(() => {
        if (!userInfo) {
            history.push('/login')
        } else {
            if (!user || !user.name || success || userInfo._id !== user._id) {
                dispatch({ type: USER_UPDATE_PROFILE_RESET })
                dispatch(getUserDetails('profile'))
                dispatch(listMyOrders())
            } else {
                setName(user.name)
                setLastName(user.lastname)
                setEmail(user.email)
            }
        }
    }, [dispatch, history, userInfo, user, success])

    const submitHandler = (e) => {
        e.preventDefault()

        if (password !== confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            dispatch(updateUserProfile({
                'id': user._id,
                'name': name,
                'lastname': lastname,
                'email': email,
                'password': password
            }))
            setMessage('Updated profile!')
        }

    }
    return (
    <div>
    <div className="py-2"
        style={{background:"#FFB4B4"}}>
                <Container className="container">
                <Row className="px-4 px-lg-5 py-lg-4 align-items-center">
                    <Col lg={6}>
                    <h1 className="h1 text-uppercase mb-0 text-light">Profile</h1>
                    </Col>

                    <Col lg={6} className="text-lg-right">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-lg-end mb-0 px-0"
                            style={{background:"#FFB4B4"}}>
                                <li className="breadcrumb-item text-light"><div>
                                    <Link to={`/`}>  
                                    Home
                                    </Link>
                                </div></li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    Profile
                                </li>
                            </ol>
                        </nav>
                    </Col>
                </Row>
            </Container>
        </div>
        

        <Row className='my-3'>
            <Col md={3}>
                <h2 className="h2 text-uppercase my-3">User Profile</h2>

                {message && <Message variant='danger'>{message}</Message>}
                {error && <Message variant='danger'>{error}</Message>}
                {loading && <Loader />}
                <Form onSubmit={submitHandler}>

                    <Form.Group controlId='name'>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            required
                            type='name'
                            placeholder='Enter name'
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='name'>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            required
                            type='name'
                            placeholder='Enter last name'
                            value={lastname}
                            onChange={(e) => setLastName(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='email'>
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            required
                            type='email'
                            placeholder='Enter Email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='password'>
                        <Form.Label className='text-muted'>Password*</Form.Label>
                        <Form.Control

                            type='password'
                            placeholder='Enter Password (optional)'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId='passwordConfirm'>
                        <Form.Label className='text-muted'>Confirm Password*</Form.Label>
                        <Form.Control

                            type='password'
                            placeholder='Confirm Password (optional)'
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        >
                        </Form.Control>
                    </Form.Group>

                    <Button type='submit' className='btn-block' variant='warning'>
                        Update
                </Button>

                </Form>
            </Col>

            <Col md={9}>
                <h2 className="h2 text-uppercase my-3">My Orders</h2>
                {loadingOrders ? (
                    <Loader />
                ) : errorOrders ? (
                    <Message variant='danger'>{errorOrders}</Message>
                ) : (
                            <Table striped responsive className='table-sm text-center'>
                                <thead>
                                    <tr>
                                        <th className="border-0" scope="col">
                                        <strong className="text-small text-uppercase">ID</strong>
                                        </th>

                                        <th className="border-0" scope="col">
                                        <strong className="text-small text-uppercase">Date</strong>
                                        </th>

                                        <th className="border-0" scope="col">
                                        <strong className="text-small text-uppercase">Total</strong>
                                        </th>

                                        <th className="border-0" scope="col">
                                        <strong className="text-small text-uppercase">Paid</strong>

                                        </th>
                                        
                                        <th className="border-0" scope="col">
                                        <strong className="text-small text-uppercase">Orders</strong>
                                        </th>
                                        
                                    </tr>
                                </thead>

                                <tbody>
                                    {orders.map(order => (
                                        <tr key={order._id}>
                                            <td>HSAU{order._id}</td>
                                            <td>{order.createdAt.substring(0, 10)}</td>
                                            <td>{order.totalPrice} AWG</td>
                                            <td >
                                                {order.isPaid ? order.paidAt.substring(0, 10) : (
                                                <i className='fas fa-times' style={{ color: 'red' }}></i>
                                            )}</td>
                                            <td>
                                                <LinkContainer to={`/order/${order._id}`}>
                                                    <Button  className='btn-sm btn-primary btn-block'
                                                    >Details</Button>
                                                </LinkContainer>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        )}
            </Col>
        </Row>
        </div>
        
    )
}

export default ProfileScreen