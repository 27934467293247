import React, { useState } from 'react'
import { Form } from 'react-bootstrap'

import { useHistory } from 'react-router-dom'
import Collapse from 'react-bootstrap/Collapse'

function SearchBox() {
    const [keyword, setKeyword] = useState('')

    const [open, setOpen] = useState(false);

    let history = useHistory()

    const submitHandler = (e) => {
        e.preventDefault()
        if (keyword) {
            history.push(`/shop?keyword=${keyword}&page=1`)
        } else {
            history.push(history.push(history.location.pathname))
        }
    }
    return (
            <Form onSubmit={submitHandler} inline>

                <button
                id="button-addon2" 
                className="btn btn-link" 
                style={{color: "#FFEF78"}}
                onClick={() => setOpen(!open)}
                aria-expanded={open}>
                    <i className="fa fa-search"></i>
                </button>
                
                <Collapse in={open}>
                    <div id="example-collapse-text">
                        <input  
                        type="search" 
                        placeholder="What're you searching for?" 
                        aria-describedby="button-addon2" 
                        className="form-control border-0 bg-light shadow-sm" 
                        onChange={(e) => setKeyword(e.target.value)}/>
                    </div>
                </Collapse>
            </Form>
    )
}

export default SearchBox
