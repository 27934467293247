import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Col, Row, Container } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Loader from '../components/Loader'
import Message from '../components/Message'
import { register } from '../actions/userActions'

function RegisterScreen({ location, history }) {

    const [name, setName] = useState('')
    const [lastname, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [message, setMessage] = useState('')

    const dispatch = useDispatch()

    const redirect = location.search ? location.search.split('=')[1] : '/'

    const userRegister = useSelector(state => state.userRegister)
    const { error, loading, userInfo } = userRegister

    useEffect(() => {
        if (userInfo) {
            history.push(redirect)
        }
    }, [history, userInfo, redirect])

    const submitHandler = (e) => {
        e.preventDefault()

        if (password !== confirmPassword) {
            setMessage('Passwords do not match')
        } else {
            dispatch(register(name, lastname, email, password))
        }

    }

    return (
     
<Container>

<Row className="row py-5 mt-4 align-items-center">

    <div className="col-md-5 pr-lg-5 mb-5 mb-md-0 text-center">
        <img 
        src="https://i.imgur.com/FQSjmx0.png" 
        alt="Healthy Skin logo" 
        className="img-fluid mb-3 d-none mx-auto d-block" />

        <h1>Create an Account</h1>
        <p className="font-italic text-muted mb-0">
            Be picky with your time, your friends and your skincare. 
            
        </p>

    </div>


    <Col md={7} lg={6} className="ml-auto">

        <form onSubmit={submitHandler}>
            <Row>   

                <div className="input-group col-lg-6 mb-4">

                    <div className="input-group-prepend">
                        <span className="input-group-text bg-white px-4 border-md border-right-0">
                            <i className="fa fa-user text-muted"></i>
                        </span>
                    </div>

                    <input 
                    id="firstName" 
                    required
                    type="text" 
                    name="firstname" 
                    placeholder="First Name" 
                    className="form-control  bg-white border-left-0 border-md" 
                    value={name}
                    onChange={(e) => setName(e.target.value)}/>
                
                </div>

                <div className="input-group col-lg-6 mb-4">

                    <div className="input-group-prepend">
                        <span className="input-group-text bg-white px-4 border-md border-right-0">
                            <i className="fa fa-user text-muted"></i>
                        </span>
                    </div>

                    <input 
                    id="lastName"
                    required 
                    type="text" 
                    name="lastname" 
                    placeholder="Last Name" 
                    className="form-control bg-white border-left-0 border-md" 
                    value={lastname}
                    onChange={(e) => setLastName(e.target.value)}/>

                </div>

                <div className="input-group col-lg-12 mb-4">

                    <div className="input-group-prepend">
                        <span className="input-group-text bg-white px-4 border-md border-right-0">
                            <i className="fa fa-envelope text-muted"></i>
                        </span>
                    </div>

                    <input 
                    id="email" 
                    required
                    type="email" 
                    name="email" 
                    placeholder="Email Address" 
                    className="form-control bg-white border-left-0 border-md"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)} />

                </div>

                <div className="input-group col-lg-6 mb-4">

                    <div className="input-group-prepend">
                        <span className="input-group-text bg-white px-4 border-md border-right-0">
                            <i className="fa fa-lock text-muted"></i>
                        </span>
                    </div>

                    <input 
                    id="password"
                    required 
                    type="password" 
                    name="password" 
                    placeholder="Password" 
                    className="form-control bg-white border-left-0 border-md"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)} />

                </div>


                <div className="input-group col-lg-6 mb-4">

                    <div className="input-group-prepend">
                        <span className="input-group-text bg-white px-4 border-md border-right-0">
                            <i className="fa fa-lock text-muted"></i>
                        </span>
                    </div>
                    <input 
                    required
                    id="passwordConfirmation" 
                    type="password" 
                    name="passwordConfirmation" 
                    placeholder="Confirm Password" 
                    className="form-control bg-white border-left-0 border-md"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}/>

                </div>

            
                <div className="form-group col-lg-12 mx-auto mb-0">
                    {message && <Message variant='danger'>{message}</Message>}
                    {error && <Message variant='danger'>{error}</Message>}
                    {loading && <Loader />}
                    
                    <button 
                    type='submit'
                    className="btn btn-primary btn-block text-uppercase mb-2 rounded-pill shadow-sm py-2">
                        <span className="font-weight-bold">Create your account</span>
                    </button>
                </div>

                
                        
                </Row>
            </form>

            <div className="text-center w-100 my-3">
                    <p className="text-muted font-weight-bold">
                        Have an Account? <span>
                         <Link
                        to={redirect ? `/login?redirect=${redirect}` : '/login'}>
                              Login
                        </Link>
                        </span>
                    </p>
            </div>
        </Col>
    </Row>
</Container>
    )
}

export default RegisterScreen
