import React, { useState, useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import Message from '../components/Message'
import Rating from '../components/Rating'
import { addToCart} from '../actions/cartActions'
import { addToFavorites, removeFromFavorites } from '../actions/productActions'

function FavoriteScreen({  match, history }) {

    const productId = match.params.id


    const [qty, setQty] = useState(1)

    const dispatch = useDispatch()

    const cart = useSelector(state => state.cart)
    const { cartItems } = cart

    const favorite = useSelector(state => state.favorite)
    const { favoriteItems } = favorite


    useEffect(() => {
        if (productId) {
            dispatch(addToFavorites(productId, qty))
        }

    }, [dispatch, productId, qty])


    const removeFromFavHandler = (id) => {
        if (window.confirm('Are you sure you want to delete this?')) { 
            dispatch(removeFromFavorites(id)) 
        }
    }


    return (
    
    <div>
        <div className="py-2"
        style={{background:"#fae0e4"}}>
            <Container>
                <Row className="row px-4 px-lg-5 py-lg-4 align-items-center">
                   
                    <Col className="col-lg-6">
                        <h1 className="h1 text-uppercase mb-0">Wish list</h1>
                    </Col>

                    <Col className="col-lg-6 text-lg-right">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb justify-content-lg-end mb-0 px-0"
                            style={{background:"#fae0e4"}}>
                                <li className="breadcrumb-item"><div>
                                    <Link to={`/`}>    
                                    Home
                                    </Link>
                                </div></li>

                                <li className="breadcrumb-item active " aria-current="page">
                                    Wish list
                                </li>
                            </ol>
                        </nav>
                    </Col>

                </Row>
            </Container>
        </div>

        
        {favoriteItems.length === 0 ? (

                        <Message variant='info my-3'>
                            
                            No favorites added to the list. <Link to='/'>Go Back</Link>
                        </Message>
                    ) : (  
            
            favoriteItems.map(favoriteItem => (  
                
                
                <Row className='py-1'>
                    
                <Col className="col">
                 
                <ul className="list-group align-items-center shadow-sm">

                    <li className="list-group-item">

                    <button
                    className="close p-2" 
                    type="button" 
                    onClick={() => removeFromFavHandler(favoriteItem.product)}>
                        <span aria-hidden="true">
                            ×
                        </span>
                    </button>

                    <div className="media align-items-lg-center  flex-column flex-lg-row p-3">
                    
                        <div className="media-body order-2 order-lg-1">

                        <h5 className="mt-0 font-weight-bold mb-2">
                            <Link to={`/product/${favoriteItem.product}`} className="reset-anchor animsition-link">
                                {favoriteItem.name}
                            </Link>   
                        </h5>
                        
                        <p className="font-italic text-muted mb-0 small">
                            {favoriteItem.description}
                        </p>

                        <div className="d-flex align-items-center justify-content-between mt-1">
                            
                            <h6 className="font-weight-bold my-2">{favoriteItem.price} AWG</h6>
                            
                            <ul className="list-inline small">
                            <Rating value={favoriteItem.rating} color={'#f8e825'} />
                            </ul>
                            
                        </div>

                        <Col className="col-sm-5">
                                <div 
                                className="btn btn-dark btn-outline-light btn-sm btn-block h-100  align-items-center justify-content-center px-0" 
                                onClick= {e => (dispatch(addToCart((favoriteItem.product), qty)))}
                                
                                >
                                    Add to cart
                                </div>
                        </Col>
                        </div>
                        
                        <img 
                        src={favoriteItem.image} 
                        alt={favoriteItem.name}
                        width="200" 
                        className="ml-lg-5 order-1 order-lg-2" />

                    </div>
                    </li>
                    </ul>
                    
                </Col>
                
            </Row>  
            
           )))}  
        </div>
         
    )
}

export default FavoriteScreen
