import React from 'react'
import { Card, Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'


function BillingScreen() {

    return (
    <div>
        <Card 
            style={{background: "url(https://images.unsplash.com/photo-1621553845587-dd7094ef9c03?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1470&q=80) center center"}} 
            className=" bg-cover3 text-white mb-4">
                <Container  className=" py-5">
                    <Card.Title className='display-4 font-weight-bold text-center py-3'>
                        Billing
                    </Card.Title>
                </Container>
            </Card>   

        <Row className="row justify-content-center">
            <Col lg={12} className="align-items-center py-3 mb-4 mb-lg-0">
                
                <Card className="mb-4">
        <Card.Body className="p-5">
          <h2 className="mb-4">Billing Questions. </h2>
          <h5 className="h5 text-uppercase">Order Status & Modifying Orders</h5>
                    <p className="lead">Before you place your order, you can view the contents and the total 
                    amount in your shopping basket. Once your order is placed, <strong>it cannot be modified. </strong> 
                    If you would like to cancel your order, please <Link to='\contact'>contact us</Link>. 
                    To check the status of an order, check the Order Details page.
                    </p>

                <div className="lead mb-4">
                    <Link to='\profile'>
                        Click here
                    </Link> for more details about your Order Status.
                </div>

                
                <div className="mb-4">
                    <h5 className="h5 text-uppercase">Problems with Shipping</h5>
                    <p className="lead">Billing Information Errors
                    Ensure that there are no typos or misspellings with your name, street address, 
                    city, state, and postal code.
                    </p>
                </div>

                <div className="mb-4">
                    <h5 className="h5 text-uppercase">Partially Canceled Orders</h5>
                    <p className="lead">Out of Stock Item After Order has been Placed
                    If an item in your order is out of stock after your order has been placed, 
                    the out of stock item will be canceled from your order and you will receive notification via email. 
                    All in stock items in your order will be shipped to you.
                    </p>
                </div> 
          
        </Card.Body>
      </Card>
            </Col>
        </Row>
        
        </div>
    )
}

export default BillingScreen
